<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/1hand" text=""></ion-back-button>
        </ion-buttons>
        <ion-title>{{ $t("Details") }}</ion-title>
        <ion-buttons slot="end">
          <ion-button
            fill="clear"
            @click.prevent="showCreateTask"
            v-if="item"
            >{{ $t("Edit") }}</ion-button
          >
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div v-if="item">
        <div class="calendar-item-title">
          <ion-img
            :src="`/assets/calendar/${item.tipo.split('_')[0]}.svg`"
            class="ico-img"
          ></ion-img>
          <h1 v-if="item.tipo == 'nota'" class="content">
            {{
              $t("Note from {name}", {
                name: `${item.created_by.name} ${item.created_by.apellidos}`
              })
            }}
          </h1>
          <h1 v-else-if="isReading" class="content">
            {{ $t(readingName(item.tipo)) }}
          </h1>
          <h1 v-else class="content">{{ item.nombre }}</h1>
        </div>

        <ion-card class="calendar-item-card reading" v-if="isReading">
          <div class="content ion-padding">
            <ion-icon
              :src="`assets/calendar/vitals/${item.tipo.split('_')[1]}.svg`"
              class="ico"
            ></ion-icon>
            <div class="detail">
              <p>
                <strong
                  >{{ new Intl.NumberFormat().format(item.nombre) }}
                  <span>{{ readingAppend(item.tipo) }}</span></strong
                >
              </p>
            </div>
          </div>
        </ion-card>

        <ion-card class="calendar-item-card" v-if="dateFrom">
          <div class="content ion-padding">
            <ion-icon :icon="timeOutline" class="ico"></ion-icon>
            <div class="detail">
              <p>
                {{ dateFrom.date }}<br /><ion-badge :class="`time after-now`">{{
                  `${dateFrom.time}h`
                }}</ion-badge>
              </p>
            </div>
          </div>
        </ion-card>

        <ion-card class="calendar-item-card" v-if="item.descripcion">
          <div class="content ion-padding">
            <ion-icon :icon="documentTextOutline" class="ico"></ion-icon>
            <div class="detail">
              <ion-label class="label" v-if="isReading">{{
                $t("Annotation")
              }}</ion-label>
              <ion-label class="label" v-else>{{
                $t("Description")
              }}</ion-label>
              <p class="multiline">
                {{ item.descripcion }}
              </p>
            </div>
          </div>
        </ion-card>

        <div class="ion-text-center">
          <ion-button
            fill="clear"
            color="danger"
            size="small"
            class="ion-margin ion-margin-top-2 delete-button"
            @click.prevent="deleteItem"
            >{{ $t("Delete {type}", { type: typeName }) }}</ion-button
          >
        </div>

        <ion-modal :is-open="showingCreateTask" @onDidDismiss="hideCreateTask">
          <create-task
            @close="hideCreateTask"
            mode="edit"
            :typeItem="item.tipo"
            :defaultItem="item"
          ></create-task>
        </ion-modal>
      </div>
      <div v-else>
        <div class="calendar-item-title">
          <ion-skeleton-text
            animated
            style="width: 40px; height: 40px; margin-end: 25px;"
            slot="start"
          ></ion-skeleton-text>
          <h1 class="content">
            <ion-skeleton-text
              animated
              style="width: 100%; height: 16px;"
            ></ion-skeleton-text>
            <ion-skeleton-text
              animated
              style="width: 100%; height: 16px;"
            ></ion-skeleton-text>
          </h1>
        </div>
        <ion-card class="calendar-item-card">
          <div class="content ion-padding">
            <ion-skeleton-text
              animated
              style="width: 20px; height: 20px; margin-end: 10px; margin-top:0"
            ></ion-skeleton-text>
            <div class="detail">
              <ion-skeleton-text
                animated
                style="width: 100%; height: 10px; margin-top:0"
              ></ion-skeleton-text>
              <ion-skeleton-text
                animated
                style="width: 100%; height: 10px"
              ></ion-skeleton-text>
              <ion-skeleton-text
                animated
                style="width: 100%; height: 10px"
              ></ion-skeleton-text>
            </div>
          </div>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonImg,
  IonCard,
  IonIcon,
  IonLabel,
  alertController,
  IonBadge,
  IonSkeletonText,
  IonModal
} from "@ionic/vue";
import { computed, onBeforeMount, ref } from "vue";
import { timeOutline, documentTextOutline } from "ionicons/icons";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { DateTime } from "@/plugins/luxon";
import CreateTask from "@/components/CreateTask.vue";
import { readingName, readingAppend } from "@/helpers";

export default {
  name: "CalendarItemDetail",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonImg,
    IonCard,
    IonIcon,
    IonLabel,
    IonBadge,
    IonSkeletonText,
    IonModal,
    CreateTask
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    let itemCalendar = false;
    const item = ref(false);
    const dateFrom = computed(() => {
      const d =
        item.value.fecha_desde != undefined
          ? DateTime.fromSQL(item.value.fecha_desde)
          : false;

      return {
        date: d.toLocaleString(DateTime.DATE_HUGE),
        time: d.toLocaleString(DateTime.TIME_24_SIMPLE)
      };
    });
    const showingCreateTask = ref(false);
    const showCreateTask = () => {
      showingCreateTask.value = true;
    };
    const hideCreateTask = () => {
      loadItem();
      showingCreateTask.value = false;
    };

    const isReading = ref(false);

    const findItemCalendar = async date => {
      let value = store.state.calendar.items.find(element => {
        return element.date == date;
      });

      if (!value || value === undefined) {
        const dateParam = DateTime.fromISO(route.params.date);

        await store.dispatch("calendar/getItems", {
          year: dateParam.year,
          month: dateParam.month,
          day: dateParam.day
        });

        value = store.state.calendar.items.find(element => {
          return element.date == date;
        });
      }

      return value;
    };

    const showError = async (message = false, goBack = true) => {
      const header = t("Error");
      if (!message) {
        message = "Registro no encontrado";
      }
      const buttons = [
        {
          text: t("Ok")
        }
      ];
      if (goBack) {
        buttons[0].handler = () => router.back();
      }
      const alert = await alertController.create({ header, message, buttons });
      alert.present();
    };

    let typeName = ref("");
    const loadItem = async () => {
      itemCalendar = await findItemCalendar(route.params.date);
      if (
        itemCalendar !== undefined &&
        itemCalendar.items !== undefined &&
        Array.isArray(itemCalendar.items)
      ) {
        item.value = itemCalendar.items.find(element => {
          return element.id == route.params.id;
        });
        isReading.value = item.value.tipo.split("_")[0] == "toma";
      }
      if (!item.value || item.value === undefined) {
        showError("Registro no encontrado", true);
      }

      switch (item.value.tipo) {
        case "nota":
          typeName.value = t("Note");
          break;

        case "recordatorio":
          typeName.value = t("Reminder");
          break;
        case "toma_tension":
        case "toma_temperatura":
        case "toma_peso":
        case "toma_azucar":
          typeName.value = t(readingName(item.value.tipo));
          break;
        case "tarea":
        default:
          typeName.value = t("Task");
          break;
      }
    };

    onBeforeMount(loadItem);

    // watch(items, async (currentValue, oldValue) => {
    //   if (currentValue !== oldValue) {
    //     itemCalendar = await findItemCalendar(route.params.date);

    //     if (
    //       itemCalendar !== undefined &&
    //       itemCalendar.items !== undefined &&
    //       Array.isArray(itemCalendar.items)
    //     ) {
    //       item.value = itemCalendar.items.find(element => {
    //         return element.id == route.params.id;
    //       });
    //     }
    //   }
    // });

    const deleteItem = async () => {
      const alert = await alertController.create({
        header: t("Delete"),
        message: t("Are you sure you want to delete this item?"),
        buttons: [
          {
            text: t("Cancel"),
            role: "cancel",
            cssClass: "light"
          },
          {
            text: t("Delete"),
            cssClass: "danger",
            handler: async () => {
              if (await store.dispatch("calendar/deleteItem", item.value)) {
                router.back();
              } else {
                showError(
                  store.state.calendar.error ||
                    t("There was an error removing the item."),
                  false
                );
              }
            }
          }
        ]
      });
      alert.present();
    };

    return {
      timeOutline,
      documentTextOutline,
      deleteItem,
      item,
      dateFrom,
      showingCreateTask,
      showCreateTask,
      hideCreateTask,
      isReading,
      readingName,
      readingAppend,
      typeName
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/theme/custom-variables";
ion-content {
  --background: var(--ion-color-light);
}

.calendar-item-title {
  --background: transparent;
  margin: var(--ion-margin, 16px);
  display: flex;

  ion-img {
    max-width: 40px;
    max-height: 40px;
    height: 100%;
    width: 100%;
    height: auto;
    margin-inline-end: 25px;
  }

  ion-label,
  .content {
    margin-top: 0;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 100%;
  }
}

.calendar-item-card {
  margin: var(--ion-margin, 16px);
  background: #ffffff;
  box-shadow: $box-shadow-default;
  border-radius: 4px;

  .content {
    display: flex;

    ion-icon {
      margin-inline-end: 10px;
      font-size: 20px;
      color: $color-orange;
    }

    p {
      margin-top: 0;
      margin-bottom: 1em;
      line-height: 1.5;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .label,
    ion-label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $color-black;
      margin-bottom: 0.5em;
      display: inline-block;
    }

    .detail {
      flex-basis: 100%;

      > :last-child {
        margin-bottom: 0;
      }
    }
  }
}

.reading ion-img {
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
  --color: var(--ion-color-primary);
}

.delete-button {
  font-weight: normal;
}

/* ¿FIX PARA LA CABECERA? ¿QUÉ LE PASA? */
ion-header ion-buttons[slot="start"] + ion-title {
  padding-left: 55px;
}
</style>
